@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeueCyr-Heavy.woff2') format('woff2'),
  url('./assets/fonts/HelveticaNeueCyr-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeueCyr-Bold.woff2') format('woff2'),
  url('./assets/fonts/HelveticaNeueCyr-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-family: 'Helvetica Neue', sans-serif;
}

.fw900 {
  font-weight: 900;
}

.fw700 {
  font-weight: 700;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

button:disabled {
  cursor: not-allowed;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul, li, h2, p, body, h1, h3, h4, ol, a {
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
}

.container {
  max-width: 1360px;
  width: 100%;
  padding: 0 20px;

  margin-left: auto;
  margin-right: auto;

  box-sizing: border-box;
}

@media (min-width: 1400px) {
  .container {
    padding: 0;
  }
}

.h2 {
  font-size: 48px;
  font-weight: 900;
  line-height: 52px;
  letter-spacing: 0;

  margin-bottom: 40px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .h2 {
    font-size: 100px;
    line-height: 90px;
  }
}
