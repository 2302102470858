.smileIcon {
  width: 22px;
  height: 22px;
}

.marqueeItem {
  display: flex;
  align-items: center;
  gap: 4px;
}

.marqueeLine {
  padding: 6px 0;

  display: flex;
  gap: 30px;
}

.rfm-marquee {
  gap: 30px;
}

.marqueeText {
  color: #000;

  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
}
